import {
  ApiBookedItem,
  ApiBooking,
  ApiItemType,
  ApiRoomContainer,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerFromJSON,
  ApiTravelerType
} from '@ibe/api';
import dayjs from 'dayjs';

const TRAVELER_PLACEHOLDER = 'Traveler';
abstract class BookingUtils {
  static findBookedItem(
    booking: ApiBooking | null | undefined,
    itemId: string
  ): ApiBookedItem | null | undefined {
    if (booking) {
      return booking.bookedItems.find(bookingItem => bookingItem.id === itemId);
    }
    return null;
  }

  static findBookedItemsByType(
    booking: ApiBooking | null | undefined,
    itemType: ApiItemType
  ): ApiBookedItem[] | undefined {
    if (booking === null || booking === undefined) {
      return undefined;
    } else {
      return booking.bookedItems.filter(
        (bookedItem: ApiBookedItem) => itemType === bookedItem.itemType
      );
    }
  }

  static getTravelersRates(roomRate: ApiRoomRate, fallback?: ApiRoomContainer): Array<ApiTraveler> {
    const travelers: Array<ApiTraveler> = [];

    for (let i = 0; i < (roomRate.paxInfo.adults || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.ADULT
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.children || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.CHILD,
        age: roomRate.paxInfo.childrenAges[i]
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.infants || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.INFANT,
        age: roomRate.paxInfo.infantsAges.at(i) || fallback?.infantsAges.at(i)
      } as ApiTraveler);
    }

    return travelers;
  }
  static mapTravelersForQuoteBooking(
    booking: ApiBooking | undefined | null,
    formResults: Record<string, ApiTraveler>[]
  ): Array<ApiTraveler> {
    if (!booking) {
      return [];
    }
    return (booking.travelers || []).map((traveler, index) => {
      return this.buildTravelerForQuoteBooking(booking, traveler, index, formResults);
    });
  }
  static buildTravelerForQuoteBooking(
    booking: ApiBooking,
    traveler: ApiTraveler,
    index: number,
    formResults: Record<string, ApiTraveler>[]
  ): ApiTraveler {
    const age = booking?.travelers.find(trav => trav.id === traveler.id)?.age;
    const travelerFormResultsByID = formResults[index];
    const inputAge = travelerFormResultsByID.birthDate
      ? dayjs().diff(dayjs((travelerFormResultsByID.birthDate as unknown) as string), 'years')
      : undefined;

    return ApiTravelerFromJSON({
      id: traveler.id,
      salutation: travelerFormResultsByID?.salutation || null,
      firstName: travelerFormResultsByID?.firstname || TRAVELER_PLACEHOLDER,
      lastName: travelerFormResultsByID?.lastname || index + 1,
      age: inputAge || age,
      birthDate: travelerFormResultsByID?.birthDate || null,
      type: traveler.type
    });
  }
}

export default BookingUtils;
