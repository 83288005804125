import React, { JSX } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import {
  LinkSwitchableMixin,
  MagnoliaAreaNode,
  MagnoliaComponentNode,
  MagnoliaDamNode
} from '@/Util/magnolia';
import styles from './Poster.module.scss';
import classNames from 'classnames';
import ImageWrapper from '@/components/ImageWrapper';
import { MEDIAQUERY_DEFAULTS, useMediaQuery } from '@ibe/components';

type Props = {
  image: MagnoliaDamNode;
  imageMobile?: MagnoliaDamNode;
  imageTablet?: MagnoliaDamNode;
  large: boolean;
  searchboxArea: MagnoliaAreaNode;
  metadata: MagnoliaComponentNode;
} & LinkSwitchableMixin;

const Poster = (props: Props): JSX.Element => {
  const { image: imageDesktop, imageTablet, imageMobile, large, searchboxArea } = props;
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.md });
  const isTabletView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.lg });
  const image =
    isMobileView && imageMobile
      ? imageMobile
      : isTabletView && imageTablet
      ? imageTablet
      : imageDesktop;

  return (
    <div className={`Poster ${classNames(styles.Poster, { [styles.PosterLarge]: large })}`}>
      <div className={`Poster__image ${styles.Poster__image}`}>
        <ImageWrapper image={image} width={100} height={100} />
      </div>
      <div className={styles.Poster__searchboxArea}>
        <div className="max-width pl-4 pr-4 pr-lg-5 pl-lg-5 pt-4 pt-md-5">
          {searchboxArea && <EditableArea content={searchboxArea} />}
        </div>
      </div>
    </div>
  );
};

export default Poster;
