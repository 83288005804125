import React, { Fragment, useMemo, useState, JSX } from 'react';
import { MagnoliaDamNode } from '@/Util/magnolia';
import { Overlay, Price, useConfig } from '@ibe/components';
import sanitize from '@/Util/sanitize';
import { useMount } from 'react-use';
import usePackageService from '@/Hooks/usePackageService';
import { ApiDurationFactory } from '@/Util/Factory/ApiDurationFacotry';
import { ApiItemType, ApiPrice, ApiPriceModifierType } from '@ibe/api';
import { ApiRoomContainerFactory } from '@/Util/Factory/ApiRoomContainerFactory';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import ImageWrapper from '@/components/ImageWrapper';
import Link from 'next/link';
import { ConfigModel, PriceFactory } from '@ibe/services';
import dayjs from 'dayjs';
import Keys from '../../Translations/generated/en/offer.json.keys';
import useTranslation from '@/Util/useTranslation';

export interface OfferProps {
  packageCode?: string;
  hotelCode: string;
  fromText?: string;
  image?: MagnoliaDamNode;
  description?: string;
  perPersonText?: string;
  duration?: string;
  numberOfAdults?: string;
  offerLink?: string;
  noLazyImage?: boolean;
  priorityImage?: boolean;
  design?: 'oldDesign' | 'newDesign';
  dateInfo: {
    dateChangeType: 'automatic' | 'fixed_date';
    startDate?: string;
    endDate?: string;
    autoRenewalOffset?: string;
    autoRenewalDuration?: string;
  };
}

const Offer = (props: OfferProps) => {
  const {
    image,
    link,
    loading,
    name,
    price,
    stars,
    tags,
    isNewDesign,
    isMagnoliaEdit
  } = useOfferHooks(props);
  const { discount, priceWithoutDiscount } = useGlobalPriceHooks(price);
  const config = useConfig();
  const { t } = useTranslation('offer');
  if (isNewDesign) {
    return (
      <NewDesignOffer
        image={image}
        link={link}
        loading={loading}
        name={name}
        price={price}
        discount={discount}
        priceWithoutDiscount={priceWithoutDiscount}
        stars={stars}
        tags={tags}
        isMagnoliaEdit={isMagnoliaEdit}
        {...props}
      />
    );
  }
  const Wrapper = isMagnoliaEdit ? Fragment : Link;

  return (
    <Wrapper href={link || ''}>
      <div className={'offerContainer'}>
        <ImageWrapper
          image={image}
          quality={5}
          priority={props.priorityImage}
          noLazy={props.noLazyImage}
          showFallbackImage
        />
        <div className="offer-top">
          <div className={'offerTopLeft'}>
            <Tags tags={tags} />
          </div>
          {props.description ? (
            <div
              className={'offerTopRight'}
              dangerouslySetInnerHTML={{ __html: sanitize(props.description || '') }}
            />
          ) : null}
        </div>
        <div className="offer-bottom">
          <div className={'offerBottomLeft'}>
            {name}
            {[...Array(stars)].map(() => '*')}
          </div>

          <div className={'offerBottomRight'}>
            {discount ? (
              <Price
                roundPrice={config.roundPricesToWholeNumber}
                className="discount__price"
                prefix="-"
                price={discount}
              />
            ) : null}
            <div>
              <span className="from__text">
                {props.fromText ? props.fromText : t(Keys.fromText)}
              </span>
              {priceWithoutDiscount ? (
                <Price
                  roundPrice={config.roundPricesToWholeNumber}
                  className="price__without__discount"
                  price={priceWithoutDiscount}
                />
              ) : null}
            </div>
            <div className={'offerPrice'}>
              <Price price={price} roundPrice={config.roundPricesToWholeNumber} />
              {loading && <Overlay />}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const NewDesignOffer = ({
  image,
  link,
  name,
  price,
  priceWithoutDiscount,
  discount,
  loading,
  stars,
  tags,
  ...props
}: {
  link: string | undefined;
  tags: string[];
  stars: number | undefined;
  price: ApiPrice | undefined;
  priceWithoutDiscount?: ApiPrice | undefined;
  discount?: ApiPrice | undefined;

  loading: boolean | undefined;
  image: MagnoliaDamNode | undefined;
  name: string | undefined;
  isMagnoliaEdit: boolean | undefined;
} & OfferProps) => {
  const config = useConfig();
  const Wrapper = props.isMagnoliaEdit ? 'div' : Link;
  const classOnEditor = props.isMagnoliaEdit ? ' h-100' : '';
  const { t } = useTranslation('offer');
  return (
    <Wrapper href={link || ''} className={`text-reset text-decoration-none  ${classOnEditor}`}>
      <div className={'new__offer__container'}>
        <div className="new__offer__container__image">
          <div className="offer-top">
            <div className={'offerTopLeft'}>
              <Tags tags={tags} />
            </div>
          </div>
          <ImageWrapper
            image={image}
            quality={5}
            priority={props.priorityImage}
            noLazy={props.noLazyImage}
            showFallbackImage
          />
        </div>
        <div className="mt-2 new__offer__container__text">
          <div>
            {name ? (
              <h3>
                {name + ' '}
                {[...Array(stars)].map(() => '*')}
              </h3>
            ) : null}
            {props.description ? (
              <div dangerouslySetInnerHTML={{ __html: sanitize(props.description || '') }} />
            ) : null}
          </div>
          <div>
            <div className="price__container text-right  ">
              {loading ? (
                <div className="loading__container">
                  <Overlay />
                </div>
              ) : (
                <>
                  {discount ? (
                    <Price
                      className="discount__price"
                      prefix="-"
                      price={discount}
                      roundPrice={config.roundPricesToWholeNumber}
                    />
                  ) : null}
                  <span className="from__text">
                    {props.fromText ? props.fromText : t(Keys.fromText)}
                  </span>
                  {priceWithoutDiscount ? (
                    <Price
                      className="price__without__discount"
                      price={priceWithoutDiscount}
                      roundPrice={config.roundPricesToWholeNumber}
                    />
                  ) : null}
                  <Price
                    className="finale__price"
                    price={price}
                    roundPrice={config.roundPricesToWholeNumber}
                  />
                </>
              )}
            </div>
            <div className="text-right per__person__price">
              {props.perPersonText ? props.perPersonText : t(Keys.perPersonText)}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Tags = ({ tags }: { tags: string[] }): JSX.Element => (
  <div className={'offer__tags'}>
    {tags.map(tag => (
      <span key={`tag-${tag}`}>{tag}</span>
    ))}
  </div>
);

export default Offer;

const useOfferHooks = (props: OfferProps) => {
  const packageService = usePackageService();
  const globalMGLProps = useGlobalMGLProps();
  const config = useConfig();
  const [name, setName] = useState<string>();
  const [image, setImage] = useState<MagnoliaDamNode | undefined>(props.image);
  const [price, setPrice] = useState<ApiPrice>();
  const [stars, setStars] = useState<number>();
  const [link, setLink] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [tags, setTags] = useState<string[]>([]);
  const [, setHotelLogo] = useState<MagnoliaDamNode>();
  const { isMagnoliaEdit } = globalMGLProps?.magnoliaContext || {};

  useMount(() => {
    setLoading(true);
    if (!!globalMGLProps?.cmsHotels) {
      const cmsHotel = globalMGLProps?.cmsHotels?.find(h => h.code === props.hotelCode);
      if (cmsHotel?.mainImage) {
        setImage(prevState => (prevState ? prevState : cmsHotel.mainImage));
      }
      if (cmsHotel?.hotelLogo) {
        setHotelLogo(cmsHotel?.hotelLogo);
      }
    }
    const [startDate, endDate] = getDatesFromProps(props, config);
    const rq = {
      packageCode: props.packageCode,
      startDate,
      endDate,
      duration: ApiDurationFactory.create({
        duration: optStringToNumber(props.duration)
      }),
      roomContainer: ApiRoomContainerFactory.create([
        { adults: optStringToNumber(props.numberOfAdults) }
      ]),
      mainServiceCodes: [props.hotelCode],
      subType: ApiItemType.PREDEFINEDPACKAGE,
      componentTypes: [],
      destination: undefined
    };
    packageService
      .searchBestPricePackages(rq)
      .then(p => {
        if (p.packages.length) {
          setPrice(p.packages[0].price);
          setStars(Number(p.packages[0].category?.description || '0'));
          setName(p.packages[0].name);
          const metaTags = p.packages[0].metaInfo
            ? p.packages[0].metaInfo.contentByMetaType?.TAGS?.content
            : undefined;
          if (metaTags) {
            setTags(metaTags.tags as Array<string>);
          }
          const urlParams = PackageParamsTransformer.encode({
            startDate: rq.startDate,
            endDate: rq.endDate,
            duration: optStringToNumber(props.duration),
            occupancy: rq.roomContainer,
            packageCode: rq.packageCode,
            itemId: p.packages[0].id,
            goToDetails: true,
            mainServiceCodes: rq.mainServiceCodes
          });
          setLink(`${props.offerLink}?${urlParams}`);
        }
      })
      .finally(() => setLoading(false));
  });

  const isNewDesign = props.design === 'newDesign';

  return {
    link,
    tags,
    stars,
    price,
    loading,
    image,
    name,
    isNewDesign,
    isMagnoliaEdit
  };
};

export const useGlobalPriceHooks = (price: ApiPrice | undefined) => {
  const globalMGLProps = useGlobalMGLProps();
  const displayStrikeThroughPrices = globalMGLProps?.config.displayStrikeThroughPrices;
  const discount = useMemo(() => {
    if (price?.modifiers?.length && displayStrikeThroughPrices) {
      const discountPrice = price?.modifiers.find(
        mod => mod.type === ApiPriceModifierType.DISCOUNT && mod.name === 'IncludedDiscount'
      );
      if (discountPrice) {
        return PriceFactory.create(Math.abs(discountPrice.absolute), price.currencyCode);
      }
    }
  }, [price]);

  const priceWithoutDiscount = useMemo(() => {
    if (price && discount && displayStrikeThroughPrices) {
      return PriceFactory.create(
        (price?.finalPrice || 0) + Math.abs(discount?.finalPrice || 0),
        price.currencyCode
      );
    }
  }, [discount, price]);
  return {
    discount,
    priceWithoutDiscount
  };
};

export const getDatesFromProps = (
  props: {
    dateInfo: {
      dateChangeType: 'automatic' | 'fixed_date';
      startDate?: string;
      endDate?: string;
      autoRenewalOffset?: string;
      autoRenewalDuration?: string;
    };
  },
  config: ConfigModel
) => {
  let { startDate, endDate } = props.dateInfo || {};
  const { autoRenewalOffset, autoRenewalDuration, dateChangeType } = props.dateInfo || {};
  if (dateChangeType === 'automatic' && autoRenewalOffset && autoRenewalDuration) {
    startDate = dayjs().add(parseInt(autoRenewalOffset), 'day').format(config.formatDate);
    endDate = dayjs(startDate, config.formatDate)
      .add(parseInt(autoRenewalDuration), 'day')
      .format(config.formatDate);
    return [startDate, endDate];
  }
  return [dayjs(startDate).format(config.formatDate), dayjs(endDate).format(config.formatDate)];
};
function optStringToNumber(optText: string | undefined): number | undefined {
  return !!optText ? parseInt(optText, 10) : undefined;
}
