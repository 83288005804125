import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CollapsibleRef,
  PackageSearchStore,
  PackageSearchValidationErrorMessage
} from '@ibe/components';
import React, { ReactNode, useState, JSX } from 'react';
import { Button, Col, Row, Tooltip } from 'reactstrap';
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faPlaneDeparture,
  faUserFriends,
  faExclamationCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import isClient from '../../../Util/globals';
import { useMount } from 'react-use';
import { observer } from 'mobx-react';

const RenderSearch = (
  _: ReactNode,
  __: ReactNode,
  DestinationSelect: ReactNode,
  TravelDatePicker: ReactNode,
  ___: ReactNode,
  TravelerPicker: ReactNode,
  SearchButton: ReactNode,
  OriginCheckboxes: ReactNode,
  ____: ReactNode,
  collapsibleRef?: CollapsibleRef,
  message?: string,
  customPackageSearchStore?: PackageSearchStore
): JSX.Element => {
  const [mounted, setMounted] = useState<boolean>(false);

  useMount(() => {
    setMounted(true);
  });

  const closeCollapsible = () => {
    if (collapsibleRef?.current) {
      collapsibleRef.current.closeCollapsible();
    }
  };

  return (
    <>
      <div className="iso__service-search justify-content-center">
        <div className="iso__service-search__closeCollapsible">
          <Button color="link" className="align-self-end" onClick={closeCollapsible}>
            X
          </Button>
        </div>
        {isClient() && mounted && (
          <Row className="iso__service-search__bar-container">
            <Col className="iso__service-search__row__1__col__2 mb-xl-0 mb-3" sm={6} xl={2}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100 overflow-hidden">{DestinationSelect}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__2__col__2 mb-xl-0 mb-3" sm={6} xl={3}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faUserFriends}
                  className="mr-1 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100">{TravelerPicker}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__2__col__1 mb-xl-0 mb-3" sm={6} xl={3}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100 overflow-hidden">{TravelDatePicker}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__1__col__1 mb-xl-0 mb-3" sm={6} xl={2}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100">{OriginCheckboxes}</div>
              </div>
            </Col>
            <Col xl={2} className="d-flex flex-column " id={'searchBottom_cont'}>
              <div id="searchBottom" className="mt-auto">
                {SearchButton}
              </div>
              <SearchButtonToolTip store={customPackageSearchStore} />
            </Col>
          </Row>
        )}
      </div>
      {message && (
        <div className="alert alert-info">
          <FontAwesomeIcon icon={faExclamationCircle} />
          &nbsp;{message}
        </div>
      )}
    </>
  );
};

export default RenderSearch;

const SearchButtonToolTip = observer(({ store }: { store?: PackageSearchStore }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  if (!store?.validationErrorMessages?.length) return <></>;
  return (
    <Tooltip
      placement={'bottom'}
      isOpen={tooltipOpen}
      target={'searchBottom'}
      popperClassName="package__search__validation-error-tooltip-popper"
      innerClassName="package__search__validation-error-tooltip-inner"
      container={'searchBottom_cont'}
      toggle={() => setTooltipOpen(true)}
      onClick={toggle}
    >
      <div className="package__search__validation-error-tooltip-inner-container">
        <div className="text-right">
          <span className="package__search__validation-error-tooltip-inner-close" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        <PackageSearchValidationErrorMessage store={store} />
      </div>
    </Tooltip>
  );
});
