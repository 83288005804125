import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Keys from '@/Translations/generated/en/Checkout-B2B.json.keys';
import useTranslation from '@/Util/useTranslation';
import { Button, Input, Alert } from 'reactstrap';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Price } from '@ibe/components';
import { ApiItemType, ApiPrice, ApiPriceModifier, ApiPriceModifierType } from '@ibe/api';
import { LoggerFactory, PriceFactory } from '@ibe/services';
import CheckoutStore from '../checkout/CheckoutStore';

const logger = LoggerFactory.get('ErrorBoundary');
type PromotionErrorType =
  | 'NEW_CODE_IS_NOT_VALID'
  | 'COUPON_CANNOT_BE_REMOVE_ADD_AUTOMATICALLY'
  | 'NEW_CODE_IS_NOT_VALID_OR_NOT_COMBINABLE'
  | 'NEW_CODE_ALREADY_USED';

const Promotion = ({ store }: { store: CheckoutStore }) => {
  const { t } = useTranslation('Checkout-B2B');
  const {
    inputRef,
    code,
    setCode,
    handleKeyDownSubmit,
    handleClearClick,
    hasError,
    setHasError,
    discount,
    currencyCode,
    usedDiscounts,
    removePromotionCode,
    applyPromotionCode
  } = usePromotionCode(store);

  return (
    <>
      <div className="bestprice-price-details__divider"></div>
      <div className="bestprice-price-details__promotion">
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faPercentage} />
          </div>
          <div className="bestprice-price-details__content">
            <div>{t(Keys.promotion.header)}</div>
          </div>
        </div>
        <div>
          <p>{t(Keys.promotion.label)}</p>
          <div className="bestprice-price-details-code__input">
            <div className="bestprice-price-details-code__input__inner">
              <Input
                innerRef={inputRef}
                value={code}
                placeholder={t(Keys.promotion.inputPlaceholder)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.code === 'Enter') handleKeyDownSubmit(e);
                }}
              />
              <FontAwesomeIcon icon={faTimesCircle} onClick={handleClearClick} />
            </div>
            <Button
              color="primary"
              className="bestprice-price-details__breakWord"
              onClick={() => applyPromotionCode(code)}
            >
              {t(Keys.promotion.apply)}
            </Button>
          </div>
          <Alert
            className="mt-2 bestprice-price-details__breakWord"
            color={'warning'}
            fade
            isOpen={!!hasError}
            toggle={() => setHasError(null)}
          >
            {hasError === 'NEW_CODE_IS_NOT_VALID' ? t(Keys.promotion.codeIsNotValid) : null}
            {hasError === 'COUPON_CANNOT_BE_REMOVE_ADD_AUTOMATICALLY'
              ? t(Keys.promotion.notRemovableAutomatic)
              : null}
            {hasError === 'NEW_CODE_IS_NOT_VALID_OR_NOT_COMBINABLE'
              ? t(Keys.promotion.codeIsNotValidOrCombinable)
              : null}
            {hasError === 'NEW_CODE_ALREADY_USED' ? t(Keys.promotion.codeAlreadyUsed) : null}
          </Alert>
        </div>
      </div>
      <div className="bestprice-price-details__divider"></div>
      {Object.keys(usedDiscounts).length > 0 && discount ? (
        <div className="mt-2">
          <p className="mb-2 font-weight-bold">{t(Keys.promotion.usedDiscountTitle)}</p>
          {Object.keys(usedDiscounts).map(key => {
            const totalPricePerDiscount = usedDiscounts[key].reduce(
              (acc, val) => acc + val.absolute,
              0
            );
            return (
              <div key={key} className="bestprice-price-details__used__discount__container pr-4">
                <span>{key}</span>
                <span>
                  {currencyCode && (
                    <Price
                      className="bestprice-price-details__promotion__price"
                      price={PriceFactory.create(Math.abs(totalPricePerDiscount), currencyCode)}
                      prefix={'-'}
                      removeZeroDecimals={false}
                      displayInline
                    />
                  )}
                </span>
                <span
                  onClick={() => removePromotionCode(key)}
                  className="bestprice-price-details__used__discount__container__icon"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
            );
          })}
          <div className="bestprice-price-details__divider"></div>
        </div>
      ) : null}
      {discount ? (
        <div className="bestprice-price-details__content__container bestprice-price-details__promotion__wrapper">
          <span>{t(Keys.promotion.priceReduction)}</span>
          {currencyCode && (
            <Price
              className="bestprice-price-details__promotion__price"
              price={PriceFactory.create(Math.abs(discount), currencyCode)}
              prefix={'-'}
              removeZeroDecimals={false}
              displayInline
            />
          )}
        </div>
      ) : null}
    </>
  );
};

const usePromotionCode = (store: CheckoutStore) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState<PromotionErrorType | null>(null);

  const handleClearClick = async () => {
    setCode('');
  };

  const handleKeyDownSubmit = (e: React.KeyboardEvent) => {
    e.preventDefault();
    applyPromotionCode(code);
  };
  const discount = useMemo(() => getTotalDiscount(store.booking?.totalDiscount), [
    store.booking?.totalDiscount
  ]);

  const getUsedPromotionCode = () => {
    const packageItem = store.booking?.bookedItems.find(
      item => item.itemType === ApiItemType.PACKAGE
    );
    const differentDiscounts: { [a: string]: ApiPriceModifier[] } = {};
    if (!packageItem) return differentDiscounts;
    Object.keys(packageItem.priceByPersonId).map(p => {
      packageItem.priceByPersonId[p].modifiers.map(mod => {
        if (mod.type !== ApiPriceModifierType.BASE && mod.absolute <= 0) {
          if (differentDiscounts[mod.description]) {
            differentDiscounts[mod.description] = [...differentDiscounts[mod.description]].concat([
              mod
            ]);
          } else {
            differentDiscounts[mod.description] = [mod];
          }
        }
      });
    });
    return differentDiscounts;
  };

  const usedDiscounts = useMemo(() => {
    return getUsedPromotionCode();
  }, [store.booking?.totalDiscount, store.booking?.promoCodes]);

  const applyPromotionCode = async (code: string): Promise<void> => {
    try {
      setHasError(null);
      const allDiscounts = Object.keys(usedDiscounts);
      if (allDiscounts.includes(code)) {
        return setHasError('NEW_CODE_ALREADY_USED');
      }
      allDiscounts.push(code);
      await store.addPromoCodesToBooking(allDiscounts);
      if (
        !store.booking?.promoCodes.includes(code) &&
        Object.keys(getUsedPromotionCode()).length > 0
      ) {
        return setHasError('NEW_CODE_IS_NOT_VALID_OR_NOT_COMBINABLE');
      }
      if (!store.booking?.promoCodes.includes(code)) {
        return setHasError('NEW_CODE_IS_NOT_VALID');
      }
      setCode('');
    } catch (err) {
      logger.error(err);
    }
  };

  const removePromotionCode = async (codeToRemove: string): Promise<void> => {
    try {
      setHasError(null);
      await store.addPromoCodesToBooking(
        store.booking?.promoCodes.filter(val => val !== codeToRemove) || []
      );

      if (
        store.booking?.promoCodes.includes(codeToRemove) ||
        (!store.booking?.promoCodes.includes(codeToRemove) &&
          getUsedPromotionCode()?.[codeToRemove]?.length)
      ) {
        return setHasError('COUPON_CANNOT_BE_REMOVE_ADD_AUTOMATICALLY');
      }
    } catch (err) {
      logger.error(err);
    }
  };
  const currencyCode = useMemo(() => store.booking?.price.currencyCode, [
    store.booking?.travelers,
    store?.booking?.bookedItems
  ]);
  return {
    inputRef,
    code,
    setCode,
    handleKeyDownSubmit,
    applyPromotionCode,
    handleClearClick,
    hasError,
    setHasError,
    discount,
    currencyCode,
    usedDiscounts,
    removePromotionCode
  };
};

export default Promotion;

export const getTotalDiscount = (discount?: ApiPrice | null): number | undefined => {
  if (discount) {
    return discount.finalPrice;
  }
  return undefined;
};
