const statusQueryParam = 'sts';
const errorStatusQueryParam = 'ER';
const canceledStatusQueryParam = 'CA';
const bookingNumberQueryParam = 'bookingId';

export type UsePaymentResponse = {
  bookingId: string | null;
  hasPaymentError: boolean;
  paymentWasCancelled: boolean;
};

export function getPaymentStatus(values: string): UsePaymentResponse {
  let bookingId: string | null = null;
  let error = false;
  let cancelled = false;

  const splitValues = values.split('&');
  if (splitValues.length > 0) {
    splitValues.forEach(value => {
      const keyValue = value.split('=');
      if (keyValue[0] === bookingNumberQueryParam) {
        bookingId = keyValue[1];
      }
      if (keyValue[0] === statusQueryParam) {
        error = keyValue[1] === errorStatusQueryParam;
        cancelled = keyValue[1] === canceledStatusQueryParam;
      }
    });
  }

  return {
    bookingId,
    hasPaymentError: error,
    paymentWasCancelled: cancelled
  };
}
