import { observer } from 'mobx-react';
import React, { useMemo, useState, JSX } from 'react';
import {
  ApiBooking,
  ApiComponent,
  ApiDirection,
  ApiFlightItem,
  ApiItemType,
  ApiSpecialRequest,
  ApiTraveler
} from '@ibe/api';
import { Button, Col, Row } from 'reactstrap';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import ReactSelect from 'react-select';
import { cloneDeep } from 'lodash-es';
import { useAppService } from '@ibe/components';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import LuggageLabelFactory from '@/Util/LuggageLabelFactory';

export interface OptionalLuggageCardProps {
  booking: ApiBooking;
  luggage: Array<ApiSpecialRequest>;
  component: ApiComponent;
  direction: ApiDirection;
  store: CheckoutStore;
}

interface LuggageOption {
  label: string;
  value: ApiSpecialRequest;
}

const OptionalLuggageCard = observer(function OptionalLuggageCard(props: OptionalLuggageCardProps) {
  const { booking, luggage, component, direction, store } = props;
  const { t } = useTranslationMGL('Checkout');
  const [selectedOptions] = useState<Array<ApiSpecialRequest>>([]);
  const appService = useAppService();
  const luggageLabelFactory = new LuggageLabelFactory(t, Keys, appService.lang);

  const mapSpecialRequestToOption = (specialRequest: ApiSpecialRequest): LuggageOption => {
    const label = luggageLabelFactory.create(specialRequest);
    return {
      label: label,
      value: specialRequest
    };
  };

  const getOptions = (): Array<LuggageOption> => {
    const options: Array<LuggageOption> = [];
    luggage
      .filter(value => value.travelerPosition === 0)
      .forEach(item => {
        const specialRequest = item as ApiSpecialRequest;
        options.push(mapSpecialRequestToOption(specialRequest));
      });
    const luggagePrice = (item: LuggageOption) => item.value.fee?.finalPrice ?? 0;
    options.sort((a, b) => {
      return luggagePrice(a) - luggagePrice(b);
    });
    return options;
  };

  const getButtonLabel = (): string => {
    const components = store.packageCart?.packageModel?.packageDetails?.[0].components;
    const flightSsrs: Array<ApiSpecialRequest> | undefined = (components
      ?.filter(
        c =>
          c.itemType === ApiItemType.FLIGHT &&
          (c.selectedItems.at(0) as ApiFlightItem).direction === direction
      )
      ?.at(0)
      ?.selectedItems.at(0) as ApiFlightItem)?.segment?.at(0)?.specialRequests;

    if (flightSsrs) {
      return ~flightSsrs.findIndex(s => s.travelerPosition !== 0) ? t(Keys.change) : t(Keys.select);
    }
    return t(Keys.select);
  };

  const options = useMemo(() => getOptions(), [luggage, t]);

  const getSelectedValue = (travelerPosition: number): LuggageOption | undefined => {
    const selectedLuggageId = luggage
      .filter(value => value.travelerPosition === travelerPosition)
      .at(0)?.id;
    if (selectedLuggageId) {
      const selectedLuggage = luggage.find(value => value.id === selectedLuggageId && !!value.meta);
      if (selectedLuggage) {
        return mapSpecialRequestToOption(selectedLuggage);
      }
    }
    return undefined;
  };

  function getTravelerLuggageOptionsSelection(traveler: ApiTraveler, index: number): JSX.Element {
    return (
      <Col className="col-12">
        <span>
          {t(Keys[(traveler.type as unknown) as keyof typeof Keys])} {index + 1}
        </span>
        <div className="mt-2">
          <ReactSelect
            value={getSelectedValue(parseInt(traveler.id, 10))}
            options={options}
            onChange={(selection: { value: ApiSpecialRequest }) => {
              const travelerPosition = parseInt(traveler.id, 10);
              const selectionIndex = selectedOptions.findIndex(
                s => s.travelerPosition === travelerPosition
              );
              if (selection) {
                const selectionCopy = cloneDeep(selection.value);
                selectionCopy.travelerPosition = travelerPosition;
                if (~selectionIndex) {
                  selectedOptions[selectionIndex] = selectionCopy;
                } else {
                  selectedOptions.push(selectionCopy);
                }
              } else if (selection === null && ~selectionIndex) {
                selectedOptions.splice(selectionIndex, 1);
              }
            }}
            isClearable={true}
            placeholder={t(Keys.select) + '...'}
          />
        </div>
      </Col>
    );
  }

  return options.length > 0 ? (
    <div className="col mb-4 optional-service">
      <div className="card h-100">
        <div className="optional-service__badge">{t(Keys.optional)}</div>
        <div className="card-body">
          <div className="card-title optional-service__headline">
            <div className="luggageCard_header">
              {direction === ApiDirection.INBOUND
                ? t(Keys.inboundLuggageSelectionHeader)
                : t(Keys.outboundLuggageSelectionHeader)}
            </div>
            <div className="luggageCard_subHeader">
              {direction === ApiDirection.INBOUND
                ? t(Keys.inboundLuggageSelectionSubHeader)
                : t(Keys.outboundLuggageSelectionSubHeader)}
            </div>
          </div>
          <div>
            <span className="luggageCard_info">
              {direction === ApiDirection.INBOUND
                ? t(Keys.inboundLuggageSelectionInfo)
                : t(Keys.outboundLuggageSelectionInfo)}
            </span>
          </div>
          <div className="card-text optional-service__description">
            <Row>
              {booking.travelers.map((traveller, index) =>
                getTravelerLuggageOptionsSelection(traveller, index)
              )}
            </Row>
          </div>
          <Row className="justify-content-end mt-4">
            <Col xs={'auto'}>
              <Button
                color="primary"
                onClick={async () => {
                  const selectedItemId = (component.selectedItems.at(0) as ApiFlightItem).id;
                  await store.packageCartSelectItems(component.id, [selectedItemId], {
                    [selectedItemId]: {
                      travelers: [],
                      count: 1,
                      date: '',
                      specialRequests: selectedOptions
                    }
                  });
                  await store.attemptBooking();
                }}
              >
                {getButtonLabel()}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default OptionalLuggageCard;
