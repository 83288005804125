import { EditablePage } from '@magnolia/react-editor';
import { GetServerSidePropsContext } from 'next/types';
import { ReactElement } from 'react';
import { EditorContextHelper } from '@magnolia/template-annotations';
import { IMagnoliaContext } from '@magnolia/template-annotations/src/service/GetMagnoliaContext';
import { NextPageWithLayout } from '@/pages/_app';
import DefaultPageLayout from '@/Layouts/DefaultPageLayout';
import nextI18NextConfig from '../../../next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  apiFetch,
  apis,
  checkoutComponent,
  config,
  ibeComponent,
  ibeSearchComponent,
  offerComponent,
  Props,
  PUBLIC_CONFIG_NODE_PATH,
  rootNodePath
} from '@/Util/magnolia';
import { LoggerFactory } from '@ibe/services';

const logger = LoggerFactory.get('index.tsx');

import { ApiBreadCrumbResponse } from '../../../api/generated';
import api from '@/Util/api';
import { Hotel } from '@/types/cms/Hotel';
import { Destination } from '@/types/cms/Destination';
import { getRequestConfig } from '@/Util/getRequestConfig';

// find component in page structure recursively
const hasComponent = (dataNode: Record<string, any>, componentNames: string[]): boolean => {
  return Object.keys(dataNode).some(key => {
    const node = dataNode[key];
    if (typeof node === 'string' && key === 'mgnl:template' && componentNames.includes(node)) {
      return true;
    } else {
      return (
        typeof node === 'object' &&
        !Array.isArray(node) &&
        node !== null &&
        hasComponent(node, componentNames)
      );
    }
  });
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { isAuthor, activateCache, ...res } = await getRequestConfig(context);
  if (res.redirect) return res;

  const magnoliaContext: IMagnoliaContext = EditorContextHelper.getMagnoliaContext(
    // i18next strips the locale from the resolvedUrl, so we need to prepend it here
    '/' + context.locale + context.resolvedUrl,
    rootNodePath,
    nextI18NextConfig.i18n.locales
  );

  const promisesRes = [];

  // Fetching page content
  const pagesRes = apiFetch(apis.pages + magnoliaContext.nodePath + '?lang=' + context.locale);
  promisesRes.push(pagesRes);

  // Fetching config
  const configRes = apiFetch(apis.config + PUBLIC_CONFIG_NODE_PATH);
  promisesRes.push(configRes);

  // inherit of header/footer areas
  if (magnoliaContext.nodePath !== rootNodePath) {
    const homepageRes = apiFetch(apis.pages + rootNodePath + '?lang=' + context.locale);
    promisesRes.push(homepageRes);
  }

  const [pageDataNormal, configData, homepageData] = await Promise.all(promisesRes).then(
    async res => {
      return Promise.all(res.map(async data => await data.json()));
    }
  );

  // Handling unknown URLs
  let pageDataNotFound;
  if (pageDataNormal.error) {
    const errorPageCMSName = configData.errorPageCMSName;
    console.error('Requested route not available: ' + JSON.stringify(pageDataNormal.error));

    let pageResNotFound;
    if (errorPageCMSName) {
      pageResNotFound = apiFetch(
        apis.pages + rootNodePath + '/' + errorPageCMSName + '/' + magnoliaContext.search
      );
      pageDataNotFound = await pageResNotFound.then(async res => {
        return await res.json();
      });
    } else {
      console.error('errorPageCMSName undefined.');
    }
  }

  const pageData = pageDataNotFound || pageDataNormal;

  const hasIbeComponent = pageData
    ? hasComponent(pageData, [ibeComponent, ibeSearchComponent])
    : false;
  const hasCheckoutComponent = pageData ? hasComponent(pageData, [checkoutComponent]) : false;

  if (homepageData && magnoliaContext.nodePath !== rootNodePath) {
    if (!pageData?.header || pageData.header['@nodes'].length === 0) {
      if (homepageData?.header) pageData.header = homepageData?.header;
    }
    if (!pageData?.footer || pageData.footer['@nodes'].length === 0) {
      if (homepageData?.footer) pageData.footer = homepageData?.footer;
    }
    // TODO: fetch templating annotations as well if we want to support editing of inherited areas
  }

  let breadCrumbResponse = [] as ApiBreadCrumbResponse['nodes'];
  if (pageData?.breadCrumbs) {
    const response = await api.getBreadCrumbNodes(pageData['@id'], context.locale || 'en');
    breadCrumbResponse = [...(response.nodes || []).reverse()];
  }

  //fetch hotel images
  let cmsHotelsData = [];
  if (hasComponent(pageData, [offerComponent])) {
    try {
      const cmsRes = await apiFetch(apis.hotels + '?lang=' + context.locale);
      const hotelsRes = await cmsRes.json();
      cmsHotelsData = hotelsRes.results;
    } catch (e) {
      logger.error(e);
    }
  }

  // fetch destinations
  let cmsDestinationsData = [];
  try {
    const cmsRes = await apiFetch(apis.destinations + '?lang=' + context.locale);
    const destinationsRes = await cmsRes.json();
    cmsDestinationsData = destinationsRes.results;
  } catch (e) {
    logger.error(e);
  }

  // Fetching header navigation
  const headerNavRes = await api.getPageNav(
    rootNodePath.length > 0 ? rootNodePath.substring(1) : rootNodePath,
    context.locale || 'en'
  );

  const props: Props = {
    breadCrumb: breadCrumbResponse,
    magnoliaContext,
    headerNav: headerNavRes,
    rootNodePath,
    page: pageData,
    config: configData || {},
    hasIbeComponent,
    hasCheckoutComponent,
    cmsHotels: (cmsHotelsData as Hotel[]) || [],
    cmsDestinations: (cmsDestinationsData as Destination[]) || [],
    // not needed when using translation module
    // see https://github.com/i18next/i18next-http-backend/tree/master/example/next
    ...(await serverSideTranslations(
      context.locale || 'en',
      nextI18NextConfig.ns,
      nextI18NextConfig
    ))
  };

  // Fetch template annotations only inside Magnolia WYSIWYG
  if (magnoliaContext.isMagnolia) {
    const templateAnnotationsRes = await apiFetch(
      apis.templateAnnotations + magnoliaContext.nodePath
    );
    props.templateAnnotations = await templateAnnotationsRes.json();
  }

  return {
    props
  };
}

const CmsPage: NextPageWithLayout<Props> = (props: Props) => {
  const { page, templateAnnotations = {}, magnoliaContext } = props;
  // @ts-ignore
  global.mgnlInPageEditor = magnoliaContext.isMagnoliaEdit;

  return <EditablePage content={page} config={config} templateAnnotations={templateAnnotations} />;
};

CmsPage.getLayout = function getLayout(page: ReactElement) {
  const MappedLayout =
    config.layoutMappings[(page.props as { page: Record<string, string> }).page['mgnl:template']] ??
    DefaultPageLayout;
  return <MappedLayout {...(page.props as { page: object }).page}>{page}</MappedLayout>;
};

export default CmsPage;
