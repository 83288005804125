import React, { useCallback, useState, JSX } from 'react';
import { IsoDropdown, useAppService, useConfig } from '@ibe/components';
import { Language } from '@/Translations';
import { getKlaroConfig } from '@/Tracking/setup';
import isClient from '@/Util/globals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const LangSwitchDrop = ({
  handleLanguageChange,
  languageOptions
}: {
  handleLanguageChange: (lang: string) => Promise<void>;
  languageOptions: { label: string; value: Language }[];
}): JSX.Element => {
  const appService = useAppService();
  const config = useConfig();

  const [languageDropDownOpen, setLanguageDropdownOpen] = useState(false);

  const toggleLanguageDropDown = useCallback(
    (): void => setLanguageDropdownOpen(prevState => !prevState),
    []
  );

  const onLanguageChange = useCallback(
    async (value: string): Promise<void> => {
      toggleLanguageDropDown();
      await handleLanguageChange(value);
    },
    [handleLanguageChange]
  );

  const updateKlaroConfig = (lang: string) => {
    if (config.useConsentManager && isClient()) {
      if (window.klaroConfig) window.klaroConfig = getKlaroConfig(lang);
      if (window.klaro) window.klaro.setup(getKlaroConfig(lang));
    }
  };

  return (
    <div className="d-flex language-dropdown">
      <div className="headerSelectionLayout text-nowrap">
        <span>
          {' '}
          <IsoDropdown
            isOpen={languageDropDownOpen}
            onDropdownToggle={setLanguageDropdownOpen}
            title={
              <div className="text-uppercase">
                {appService.lang.substring(0, 2)}&nbsp;
                {languageDropDownOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            }
          >
            {languageOptions
              .filter(lang => lang.value !== appService.lang)
              .map(option => (
                <div
                  className="text-center dropdown-item p-0"
                  key={`lang_${option.value}`}
                  onClick={(): void => {
                    onLanguageChange(option.value);
                    updateKlaroConfig(option.value);
                  }}
                  onKeyDown={(event): void => {
                    event.preventDefault();
                    if (event.keyCode === 13) {
                      onLanguageChange(option.value);
                      updateKlaroConfig(option.value);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {option?.label?.toUpperCase()}
                </div>
              ))}
          </IsoDropdown>
        </span>
      </div>
    </div>
  );
};

export default LangSwitchDrop;
