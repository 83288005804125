import { observer } from 'mobx-react';
import React from 'react';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import { ApiComponentType, ApiItemType, ApiService, ApiServiceUnit, ApiTransfer } from '@ibe/api';
import OptionalServiceCard from '@/components/checkout/OptionalServiceCard';

export interface OptionalInsuranceServicesProps {
  store: CheckoutStore;
}

const OptionalTransferServices = observer(function OptionalTransferServices(
  props: OptionalInsuranceServicesProps
) {
  const { store } = props;

  const components =
    store.packageCart?.packageModel.packageDetails &&
    store.packageCart?.packageModel.packageDetails[0].components;
  const optionalTransferComponents =
    components &&
    components.filter(
      component =>
        component.itemType === ApiItemType.TRANSFER &&
        component.componentType === ApiComponentType.OPTIONAL
    );

  const flightComponent =
    components &&
    components.find(
      component =>
        component.itemType === ApiItemType.FLIGHT &&
        component.componentType === ApiComponentType.REQUIRED &&
        component.selectableItems &&
        component.selectableItems.length > 1
    );
  const selectedSecondMatchCode = flightComponent?.selectedItems[0].secondMatchCode;

  const regularTransferComponents =
    components &&
    components.filter(
      component =>
        component.itemType === ApiItemType.TRANSFER &&
        component.componentType === ApiComponentType.REQUIRED &&
        component.selectableItems &&
        component.selectableItems.length > 1
    );

  const handleSelectTransfer = async (componentId: string, transferUnitId: string) => {
    await store.packageCartAddItem(componentId, transferUnitId);
    await store.attemptBooking();
  };

  const handleRemoveTransfer = async (componentId: string, transferUnitId: string) => {
    await store.packageCartRemoveItem(componentId, transferUnitId);
    await store.attemptBooking();
  };

  const handleSelectRegularIncludedTransfer = async (componentId: string) => {
    const selectedIds = new Array<string>();
    const currentComponent =
      regularTransferComponents && regularTransferComponents.find(c => c.id === componentId);

    const regularIncludedServiceUnit = currentComponent?.selectableItems.find(service => {
      const transfer = service as ApiTransfer;
      return (
        transfer.defaultService === true && transfer.secondMatchCode === selectedSecondMatchCode
      );
    });
    if (regularIncludedServiceUnit) {
      selectedIds.push(regularIncludedServiceUnit.id);
    }
    await store.packageCartSelectItems(componentId, selectedIds);
    await store.attemptBooking();
  };

  const handleReplaceRegularTransfer = async (componentId: string, transferUnitId: string) => {
    await store.packageCartSelectItems(componentId, [transferUnitId]);
    await store.attemptBooking();
  };

  return (
    <>
      {regularTransferComponents?.map(component => {
        return component.selectableItems
          .filter(service => {
            const transfer = service as ApiTransfer;
            return (
              transfer.defaultService === false &&
              transfer.secondMatchCode === selectedSecondMatchCode
            );
          })
          .map(service => {
            const transfer = service as ApiTransfer;
            return transfer.units.map(unit => {
              return (
                <OptionalServiceCard
                  key={transfer.id + unit.id}
                  component={component}
                  service={service as ApiService}
                  unit={unit as ApiServiceUnit}
                  handleRemoveService={handleSelectRegularIncludedTransfer}
                  handleSelectService={handleReplaceRegularTransfer}
                />
              );
            });
          });
      })}
      {optionalTransferComponents?.map(component => {
        return component.selectableItems
          .filter(service => {
            const transfer = service as ApiTransfer;
            return (
              transfer.defaultService === false &&
              transfer.secondMatchCode === selectedSecondMatchCode
            );
          })
          .map(service => {
            const transfer = service as ApiTransfer;
            return transfer.units.map(unit => {
              return (
                <OptionalServiceCard
                  key={transfer.id + unit.id}
                  component={component}
                  service={service as ApiService}
                  unit={unit as ApiServiceUnit}
                  handleRemoveService={handleRemoveTransfer}
                  handleSelectService={handleSelectTransfer}
                />
              );
            });
          });
      })}
    </>
  );
});

export default OptionalTransferServices;
