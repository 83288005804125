/** @jsxRuntime classic */
/** @jsx jsx */
// eslint-disable-next-line
import { jsx } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState, ChangeEvent, ReactElement } from 'react';
import { useMeasure } from 'react-use';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Keys from '@/Translations/generated/en/login-form.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import {
  useApi,
  useWindow,
  Error,
  Info,
  OverlayContainer,
  Overlay,
  GoogleReCaptchaRefType,
  GoogleReCaptcha
} from '@ibe/components';
import { Credentials } from '@ibe/services';

const defaults: Credentials = { merchant: '', password: '', user: '', token: '' };
type LoginFormProps = {
  children?: never;
  isDisabled?: boolean | undefined;
  hasResetPassword?: boolean;
  mandator?: string;
  initialValues?: Partial<Credentials>;
  mainError?: string;
  fieldErrors?: FormErrors;
  withCaptcha?: boolean;
  onSubmit: (values: Credentials) => Promise<void>;
};
export type CredentialKey = keyof Credentials;
export type FormErrors = Partial<Record<CredentialKey, string[]>>;

const Field = (props: {
  field: string;
  placeholder?: string;
  isPassword?: boolean;
  isDisabled?: boolean;
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
  autoComplete?: 'on' | 'off';
}) => {
  const {
    field,
    placeholder,
    isPassword,
    value,
    onChange,
    isDisabled,
    errors,
    autoComplete
  } = props;

  const error = errors || [];
  const hasErrors = error.length > 0;
  const disabled = isDisabled !== undefined ? isDisabled : false;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  };

  return (
    <FormGroup>
      <Input
        type={isPassword ? 'password' : 'text'}
        name={field}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        className={hasErrors ? 'is-invalid' : ''}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChange(e)}
        autoComplete={autoComplete}
      />
      {error.map(er => (
        <div key={er.toString()} className="invalid-feedback">
          {er}
        </div>
      ))}
    </FormGroup>
  );
};

const LoginForm = (props: LoginFormProps): ReactElement => {
  const window = useWindow();
  const { t } = useTranslationMGL('login-form');
  const api = useApi();

  const {
    onSubmit,
    mainError,
    isDisabled,
    initialValues,
    fieldErrors,
    hasResetPassword,
    withCaptcha
  } = props;

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const isSmall = width <= 310;

  const error = mainError && mainError !== '' ? mainError : null;
  const formErrors = fieldErrors || {};
  const defaultValues = { ...defaults, ...(initialValues || {}) };

  const [currentValues, setCurrentValues] = useState<Credentials>(defaultValues);
  const [agencyNumber, setAgencyNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isAgencyNumberFieldInvalid, setAgencyNumberFieldInvalid] = useState(false);
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [isEmailFieldInvalid, setEmailFieldInvalid] = useState(false);
  const [isForgotPasswordSuccessed, setForgotPasswordSuccessed] = useState(false);
  const [isForgotPasswordFailed, setForgotPasswordFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowingErrors, setShowingErrors] = useState(true);
  const reCaptchaRef = useRef<GoogleReCaptchaRefType>(null);
  const localStorageAgencyNumber = localStorage.getItem('agencyNumber');

  const toggleForgotPasswordModal = (): void => {
    setForgotPasswordSuccessed(false);
    setForgotPasswordFailed(false);
    setForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  };

  const handlePasswordChange = (e: React.SyntheticEvent): void => {
    setAgencyNumberFieldInvalid(!agencyNumber);
    setEmailFieldInvalid(!email);
    setForgotPasswordSuccessed(false);
    setForgotPasswordFailed(false);

    e.preventDefault();

    if (agencyNumber && email) {
      setLoading(true);
      api
        .resetPassword({
          agencyNumber: agencyNumber.trim(),
          user: email.trim()
        })
        .then(() => setForgotPasswordSuccessed(true))
        .catch(() => setForgotPasswordFailed(true))
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (field: CredentialKey, value: string): void => {
    if (field === 'merchant') {
      localStorage.setItem('agencyNumber', value);
    }
    setCurrentValues((values: Credentials) => ({ ...values, [field]: value }));
  };

  const handleCaptchaChange = (token: string | null): void => {
    setCurrentValues((values: Credentials) => ({ ...values, token }));
    setShowingErrors(false);
  };

  const handleCaptchaExpired = (): void => {
    setCurrentValues((values: Credentials) => ({ ...values, token: null }));
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    await onSubmit(currentValues);
    reCaptchaRef.current?.reset();
    setCurrentValues((values: any) => ({ ...values, token: '' }));
    setShowingErrors(true);
  };

  useEffect(() => {
    currentValues.merchant = localStorageAgencyNumber || currentValues.merchant;
  }, [agencyNumber]);

  return (
    <div ref={ref}>
      {!!error && <Error message={error as string} />}
      <Form noValidate onSubmit={handleSubmit}>
        <Field
          isDisabled={isDisabled}
          value={currentValues.merchant}
          errors={isShowingErrors ? formErrors.merchant : undefined}
          onChange={(v: string): void => {
            setAgencyNumber(v);
            handleChange('merchant', v);
          }}
          field="merchant"
          placeholder={t(Keys.merchant)}
        />
        <Field
          isDisabled={isDisabled}
          value={currentValues.user}
          errors={isShowingErrors ? formErrors.user : undefined}
          onChange={(v: string): void => handleChange('user', v)}
          field="user"
          placeholder={t(Keys.user)}
        />
        <Field
          isDisabled={isDisabled}
          value={currentValues.password}
          errors={isShowingErrors ? formErrors.password : undefined}
          onChange={(v: string): void => handleChange('password', v)}
          field="password"
          placeholder={t(Keys.password)}
          isPassword
          autoComplete="off"
        />
        {withCaptcha && (
          <FormGroup className="required">
            {isSmall ? (
              <GoogleReCaptcha
                onChange={handleCaptchaChange}
                onExpired={handleCaptchaExpired}
                compact
                ref={reCaptchaRef}
              />
            ) : (
              <div>
                <GoogleReCaptcha
                  onChange={handleCaptchaChange}
                  onExpired={handleCaptchaExpired}
                  ref={reCaptchaRef}
                />
              </div>
            )}

            {formErrors.token &&
              isShowingErrors &&
              formErrors.token.map(() => (
                <div className="text-danger" key={formErrors.token?.join()}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  &nbsp;
                  {formErrors.token}
                </div>
              ))}
          </FormGroup>
        )}
        <Button color="primary" block type="submit" disabled={isDisabled}>
          {t(Keys.submit)}
        </Button>
      </Form>
      {hasResetPassword && (
        <React.Fragment>
          <Row className="mt-2">
            <Col>
              <Button color="link" className="p-0" onClick={toggleForgotPasswordModal}>
                {`${t(Keys.forgotPassword)}?`}
              </Button>
            </Col>
          </Row>
          <Modal
            isOpen={isForgotPasswordModalOpen}
            className="iso__modal-dialog"
            toggle={toggleForgotPasswordModal}
            container={window?.document?.getElementById('iso') || undefined}
          >
            <OverlayContainer>
              <React.Fragment>
                <ModalHeader className="justify-content-center" toggle={toggleForgotPasswordModal}>
                  <div className="h5">{t(Keys.forgotPassword)}</div>
                </ModalHeader>
                <ModalBody>
                  <div className="mb-2">
                    <Input
                      invalid={isAgencyNumberFieldInvalid}
                      type="text"
                      name="agencyNumber"
                      id="forgot_password_agencyNumber_field"
                      value={currentValues.merchant}
                      placeholder={t(Keys.agency)}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleChange('merchant', e.target.value);
                        setAgencyNumber(e.target.value);
                        setAgencyNumberFieldInvalid(!e.target.value);
                      }}
                    />
                    <FormFeedback>{t(Keys.requiredField)}</FormFeedback>
                  </div>
                  <div className="mb-2">
                    <Input
                      invalid={isEmailFieldInvalid}
                      type="text"
                      name="email"
                      id="forgot_password_email_field"
                      placeholder={t(Keys.email)}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        setEmail(e.target.value);
                        setEmailFieldInvalid(!e.target.value);
                      }}
                    />
                    <FormFeedback>{t(Keys.requiredField)}</FormFeedback>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="mb-2 text-right">
                    <Button
                      className="text-uppercase"
                      color="primary"
                      onClick={handlePasswordChange}
                    >
                      {t(Keys.submit)}
                    </Button>
                  </div>
                </ModalFooter>
                <Container>
                  {isForgotPasswordSuccessed && (
                    <Row className="no-gutters mt-2">
                      <Col xs={12}>
                        <Info message={t(Keys.forgotPasswordSuccessText)} />
                      </Col>
                    </Row>
                  )}
                  {isForgotPasswordFailed && (
                    <Row className="no-gutters mt-2">
                      <Col xs={12}>
                        <Error message={t(Keys.forgotPasswordFailText)} />
                      </Col>
                    </Row>
                  )}
                </Container>
              </React.Fragment>
              {loading && <Overlay />}
            </OverlayContainer>
          </Modal>
        </React.Fragment>
      )}
    </div>
  ) as ReactElement;
};
export default LoginForm;
