import React, { useState, JSX } from 'react';
import { Carousel, CarouselIndicators, CarouselControl, CarouselItem } from 'reactstrap';
import sanitize from '@/Util/sanitize';
import dayjs from 'dayjs';
import styles from './Quotes.module.scss';
import Image from 'next/image';

export type Feedback = {
  '@id': string;
  author?: string;
  date?: string;
  richText: string;
};

const StaticQuotes = ({ feedbacks }: { feedbacks: Feedback[] }): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  const next = (): void => {
    if (animating) return;
    setActiveIndex(value => (value === feedbacks.length - 1 ? 0 : value + 1));
  };

  const previous = (): void => {
    if (animating) return;
    setActiveIndex(value => (value === 0 ? feedbacks.length - 1 : value - 1));
  };

  const goToIndex = (index: number): void => {
    if (animating) return;
    setActiveIndex(index);
  };

  const slides = feedbacks.map(feedback => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      className={styles.quotesItem}
      key={feedback['@id']}
    >
      {!!feedback.richText ? (
        <div
          className={styles.quotesText}
          dangerouslySetInnerHTML={{ __html: sanitize(feedback.richText) }}
        />
      ) : (
        <></>
      )}
      {!!feedback.author || !!feedback.date ? (
        <div className={styles.quotesAuthor}>{`${!!feedback.author ? feedback.author : ''}${
          !!feedback.date ? ` (${dayjs(feedback.date).format('MMMM YYYY')})` : ''
        }`}</div>
      ) : (
        <></>
      )}
    </CarouselItem>
  ));

  return (
    <div className={styles.quotesContainer}>
      <Carousel
        className={styles.quotes}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={10000}
      >
        <CarouselIndicators
          className={styles.quotesIndicators}
          items={feedbacks}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          className={styles.quotesControlLeft}
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          className={styles.quotesControlRight}
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      <Image
        className={styles.quotesIconLeft}
        src="/assets/quote_icon_left.svg"
        alt="quotes icon left"
        width={70}
        height={70}
        loader={p => p.src}
      />
      <Image
        className={styles.quotesIconRight}
        src="/assets/quote_icon_right.svg"
        alt="quotes icon right"
        width={70}
        height={70}
        loader={p => p.src}
      />
    </div>
  );
};

export default StaticQuotes;
