import React, { JSX } from 'react';
import { ApiBestPricePackageModel, ApiPackageModel } from '@ibe/api';
import { PackageItemSelection, PackageParams } from '@ibe/components';
import PackageListItem from './PackageListItem';

const RenderListItem = (
  item: ApiPackageModel,
  _?: number,
  onItemSelect?: (selection: Partial<PackageItemSelection>) => void,
  eachItemSearchParams?: PackageParams
): JSX.Element => {
  const bestPriceItem = item as ApiBestPricePackageModel;

  return (
    <div key={item.id} className="mb-3">
      <PackageListItem
        item={bestPriceItem}
        onSelect={onItemSelect}
        eachItemSearchParams={eachItemSearchParams}
      />
    </div>
  );
};

export default RenderListItem;
