import { ButtonProps } from 'reactstrap';
import { ElementType } from 'react';
import { LinkSwitchableMixin } from '@/Util/magnolia';
import { getNextLinkAttrs, LinkPropsType } from '../linkList/Link';

import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface Props extends LinkSwitchableMixin {
  label: string;
  isSecondaryStyle?: boolean;
  isFullWidth?: boolean;
  additionalClasses?: string;
  isButtonTag?: boolean;
  openNewBrowserWindow?: boolean;

  // I REMOVE IT, BUT SHOULD BE SPECIFICALLY REQUESTED BY THE CUSTOMER AS THIS CAN BRING SERIOUS SECURITY RISK.
  // onClickAction?: string;
}

const CustomButton = (props: Props & LinkSwitchableMixin) => {
  const [attrs, Tag] = getButtonAttributes(props);
  return (
    <div className={'d-flex w-100 justify-content-center'}>
      <Tag {...attrs}>
        <span>
          <FontAwesomeIcon icon={faCaretRight} />
          &nbsp;&nbsp;
          {props.label}
        </span>
      </Tag>
    </div>
  );
};

const getButtonAttributes = (
  props: Props & LinkSwitchableMixin
): [ButtonProps | LinkPropsType, ElementType] => {
  const {
    isSecondaryStyle,
    isFullWidth,
    additionalClasses,
    isButtonTag,
    linkTypedownload,
    linkTypeexternal,
    linkTypepage
    // onClickAction
  } = props;

  // const onClick = (e: MouseEvent) => {
  //   e.preventDefault();
  //   try {
  //     if (onClickAction) eval(onClickAction);
  //   } catch (error) {
  //     logger.error(error);
  //   }
  // };

  let attr: ButtonProps = {
    className: `iso__button btn btn-${isSecondaryStyle ? 'primary' : 'secondary'} ${
      isFullWidth ? 'w-100' : ''
    } ${additionalClasses ? additionalClasses : ''}`
  };

  // if (onClickAction) attr = { ...attr, onClick: onClick };

  const hasLink = !!(linkTypedownload || linkTypeexternal || linkTypepage);

  const link = getNextLinkAttrs(props);
  const Tag: ElementType = isButtonTag ? 'button' : hasLink ? Link : 'a';

  if (hasLink) {
    const linkAttr = ({ ...attr, ...link } as unknown) as LinkPropsType;
    return [linkAttr, Tag];
  }

  return [attr, Tag];
};

export default CustomButton;
