import React, { Fragment, JSX } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { Col, Row } from 'reactstrap';
import sanitize from '@/Util/sanitize';
import { MEDIAQUERY_DEFAULTS } from '@ibe/components';

type Spacings = {
  spacingBottomDesktop: string;
  spacingBottomMobile: string;
  spacingBottomTablet: string;
  spacingLeftDesktop: string;
  spacingLeftMobile: string;
  spacingLeftTablet: string;
  spacingRightDesktop: string;
  spacingRightMobile: string;
  spacingRightTablet: string;
  spacingTopDesktop: string;
  spacingTopMobile: string;
  spacingTopTablet: string;
};

type DigitType = '1' | '2' | '3' | '4';
type PropType = 'fixedColArea';
type Property = `${PropType}${DigitType}`;
type Props = { [key in Property]: Spacings } & {
  layout: string;
  inlineStyles: string;
  inlineStylesheet: string;
};

const getStyleString = (props: Spacings, key: number): string => {
  return `
    .fixed-column-layout-column-${key} {
      padding-top: ${props.spacingTopMobile || '10'}px;
      padding-right: ${props.spacingRightMobile || '10'}px;
      padding-bottom: ${props.spacingBottomMobile || '20'}px;
      padding-left: ${props.spacingLeftMobile || '10'}px;
    }
    
    @media only screen and (min-width: ${MEDIAQUERY_DEFAULTS.md}px) {
        .fixed-column-layout-column-${key} {
          padding-top: ${props.spacingTopTablet || '15'}px;
          padding-right: ${props.spacingRightTablet || '12'}px;
          padding-bottom: ${props.spacingBottomTablet || '30'}px;
          padding-left: ${props.spacingLeftTablet || '12'}px;
        }
      }
      
    @media only screen and (min-width: ${MEDIAQUERY_DEFAULTS.lg}px) {
      .fixed-column-layout-column-${key} {
        padding-top: ${props.spacingTopDesktop || '20'}px;
        padding-right: ${props.spacingRightDesktop || '15'}px;
        padding-bottom: ${props.spacingBottomDesktop || '40'}px;
        padding-left: ${props.spacingLeftDesktop || '15'}px;
      }
    }
  `;
};

const FixedColumnLayout = (props: Props): JSX.Element => {
  const { layout, inlineStyles, inlineStylesheet } = props;
  const colWidths = layout.split('x');
  const colCount = colWidths.length;

  return (
    <Row className={`fixed-column-layout${!!inlineStyles ? ` ${inlineStyles}` : ''}`}>
      {!!inlineStylesheet ? (
        <style dangerouslySetInnerHTML={{ __html: sanitize(inlineStylesheet) }} />
      ) : (
        <></>
      )}
      {[...Array(colCount).keys()].map(key => {
        const prop = props[`fixedColArea${key + 1}` as keyof Props];
        return !!prop ? (
          <Fragment key={key}>
            <style>{getStyleString(prop as Spacings, key)}</style>
            <Col
              className={`fixed-column-layout-column-${key}`}
              xs={12}
              sm={parseInt(colWidths[key], 10)}
            >
              <EditableArea content={prop} className={'w-100 h-100'} />
            </Col>
          </Fragment>
        ) : (
          <Fragment key={key} />
        );
      })}
    </Row>
  );
};

export default FixedColumnLayout;
