import React, { useMemo, useState, JSX } from 'react';
import { ApiBestPricePackageModel, ApiGeoUnitFromJSON, ApiImage, ApiImageFromJSON } from '@ibe/api';
import {
  ImageGallerySlider,
  MapsWrapper,
  MEDIAQUERY_DEFAULTS,
  PackageSearchParams,
  PictureGallery,
  Price,
  ScrollTabs,
  ShowMore,
  StarRating,
  useMediaQuery
} from '@ibe/components';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '../../Translations/generated/en/package-details.json.keys';
import { parseInt } from 'lodash-es';
import PrintInfo from '@/components/PackageDetails/PrintInfo';
import { useMount } from 'react-use';

export const DETAILS_INCLUDED_SERVICES_ID = 'details-included-services';

export type LocationMetaInfo = Partial<{
  description: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}>;

const PackageDetails = (props: {
  item: ApiBestPricePackageModel;
  searchParams: PackageSearchParams;
  expandForPrint: boolean;
}): JSX.Element => {
  const { item, searchParams, expandForPrint } = props;
  const { t } = useTranslationMGL('package-details');
  const [modalOpenImageIdx, setModalOpenImageIdx] = useState<number | undefined>(undefined);
  const [galleryChangeTrigger, setGalleryChangeTrigger] = useState<boolean>(false);
  const matches = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [location, setLocation] = useState<LocationMetaInfo | undefined>(undefined);

  const getLocationMetaInfo = (item: ApiBestPricePackageModel): LocationMetaInfo | undefined => {
    return item.metaInfo ? item.metaInfo.contentByMetaType?.LOCATION?.content : undefined;
  };

  useMount(async () => {
    setLocation(getLocationMetaInfo(item));
  });

  const images = useMemo(() => {
    let items: ApiImage[] = [];
    if (!!item?.media?.mainImage) {
      items = [...items, { ...ApiImageFromJSON({ url: item?.media?.mainImage?.large?.url }) }];
    }

    !!item?.media?.images.forEach(imageContainer => {
      if (imageContainer) {
        items = [...items, { ...ApiImageFromJSON({ url: imageContainer.large?.url }) }];
      }
    });
    return items;
  }, [item]);

  const toggleModal = (): void => {
    setModalOpenImageIdx(typeof modalOpenImageIdx !== 'undefined' ? undefined : 0);
  };

  const toggleTrigger = (): void => {
    setGalleryChangeTrigger(value => !value);
  };

  const handleImageClick = (imageIdx: number): void => {
    setModalOpenImageIdx(imageIdx);
    toggleTrigger();
  };

  const getTags = (): JSX.Element => {
    const tagsContent = item.metaInfo ? item.metaInfo.contentByMetaType?.TAGS?.content : undefined;
    return (
      <>
        {tagsContent && tagsContent.tags ? (
          <div className={'best-price-package-details__tags'}>
            {(tagsContent.tags as Array<string>).map(tag => (
              <span key={`tag-${tag}`}>{tag}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="best-price-package-details">
      <div className="best-price-package-details__logo hide-in-screen" />
      <div className="best-price-package-details__gallery-container">
        <PictureGallery
          images={images}
          onImageClick={handleImageClick}
          hideButtons
          twoSmallImagesMode
        />
        {item.hotelLogo?.extraSmall?.url && (
          <div className={'best-price-package-details__gallery-container__hotel-logo-container'}>
            <img
              className={'best-price-package-details__gallery-container__hotel-logo'}
              src={item.hotelLogo.extraSmall.url}
              alt=""
            />
          </div>
        )}
      </div>
      {getTags()}
      <div className="hide-in-print">
        <ScrollTabs
          anchors={[
            { label: t(Keys.overview), id: 'details-overview' },
            ...(!!location?.description || (!!location?.latitude && !!location?.longitude)
              ? [{ label: t(Keys.location), id: 'details-location' }]
              : []),
            ...(!!location?.description
              ? [{ label: t(Keys.accommodation), id: 'details-accommodation' }]
              : []),
            { label: t(Keys.includedServices), id: DETAILS_INCLUDED_SERVICES_ID }
          ]}
          scrollOffset={60}
        />
      </div>
      <div className="d-flex justify-content-between">
        <h2 id="details-overview">
          <span>{item?.name || ''}</span>
          {!!item?.category?.code && (
            <span className="ml-3">
              <StarRating numberOfStars={parseInt(item.category.code, 10)} />
            </span>
          )}
        </h2>
        <div className="bestprice-price-details__total__price hide-in-screen">
          <Price price={item.price} />
        </div>
      </div>
      <p className="best-price-package-details__geo-location">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span className="ml-2">{item?.geoAssignment?.location || ''}</span>
      </p>
      {item?.shortDescription && (
        <ShowMore
          lines={7}
          buttonClass="btn-secondary"
          more={t(Keys.show_more)}
          less={t(Keys.show_less)}
          className="best-price-package-details__short mt-2"
          showMoreRender={ShowMoreButton}
          showLessRender={ShowLessButton}
          showFullContentWithoutCta={expandForPrint}
          showAllInitially
        >
          {item.shortDescription}
        </ShowMore>
      )}
      <PrintInfo item={item} searchParams={searchParams} />
      {(!!location?.description || (!!location?.latitude && !!location?.longitude)) && (
        <>
          <h2 id="details-location">{t(Keys.location)}</h2>
          <Row>
            <Col
              xs={12}
              md={!!location?.latitude && !!location?.longitude ? 8 : 12}
              className="best-price-package-details__location__container"
            >
              {!!location?.description && (
                <ShowMore
                  lines={7}
                  more={t(Keys.show_more)}
                  less={t(Keys.show_less)}
                  className="best-price-package-details__location"
                  showMoreRender={ShowMoreButton}
                  showLessRender={ShowLessButton}
                  showFullContentWithoutCta={expandForPrint}
                  showAllInitially
                >
                  {location.description}
                </ShowMore>
              )}
            </Col>
            {!!location?.latitude && !!location?.longitude && (
              <Col xs={12} md={4} className="best-price-package-details__map hide-in-print">
                <MapsWrapper
                  markers={[
                    {
                      iconUrl: '/leaflet-marker.svg',
                      geoData: {
                        location: item?.name || '',
                        latitude: location.latitude,
                        longitude: location.longitude,
                        geoUnit: item?.geoAssignment?.geoUnit || ApiGeoUnitFromJSON(null)
                      },
                      name: item?.name || ''
                    }
                  ]}
                  centerMap={{
                    lat: location.latitude,
                    lng: location.longitude
                  }}
                  zoomMap={11}
                  containerHeight={matches ? undefined : '300px'}
                  googleMapsOptions={{
                    panControls: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false
                  }}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      {item?.description && (
        <>
          <h2 id="details-accommodation" className="mt-3">
            {t(Keys.accommodation)}
          </h2>
          <ShowMore
            lines={7}
            more={t(Keys.show_more)}
            less={t(Keys.show_less)}
            className="best-price-package-details__long"
            showMoreRender={ShowMoreButton}
            showLessRender={ShowLessButton}
            showFullContentWithoutCta={expandForPrint}
            showAllInitially
          >
            {item.description}
          </ShowMore>
        </>
      )}
      <Modal
        className="best-price-package-details__modal"
        isOpen={typeof modalOpenImageIdx !== 'undefined'}
        toggle={toggleModal}
        container={document.getElementById('iso') || undefined}
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimes}
            className="best-price-package-details__modal__close"
            onClick={(): void => setModalOpenImageIdx(undefined)}
          />
          <Row className="p-2 best-price-package-details__modal__name-row align-content-end">
            <Col className="">
              <Row className="mb-2">
                {!!item?.category?.code && (
                  <span className="mr-3">
                    <StarRating numberOfStars={parseInt(item.category.code, 10)} />
                  </span>
                )}
                <span className="best-price-package-details__geo-location">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span className="ml-2">{item?.geoAssignment?.location || ''}</span>
                </span>
              </Row>
              <Row>
                <span className="h4">{item?.name || ''}</span>
              </Row>
            </Col>
          </Row>
          <ImageGallerySlider
            images={images}
            initialGalleryImageIndex={modalOpenImageIdx}
            galleryChangeTrigger={galleryChangeTrigger}
          />
        </ModalBody>
      </Modal>
      <span id="details-included-services" />
    </div>
  );
};

const ShowMoreButton = (toggleTruncate: () => void, label: string) => {
  return (
    <ShowTruncateButton toggleTruncate={toggleTruncate} label={label} className="showMoreButton" />
  );
};

const ShowLessButton = (toggleTruncate: () => void, label: string) => {
  return (
    <ShowTruncateButton toggleTruncate={toggleTruncate} label={label} className="showLessButton" />
  );
};

const ShowTruncateButton = ({
  toggleTruncate,
  label,
  className
}: {
  toggleTruncate: () => void;
  label: string;
  className: string;
}): JSX.Element => {
  return (
    <Container className={className}>
      <Row>
        <Col>
          <hr />
        </Col>
        <Col>
          <Button onClick={toggleTruncate}>{label}</Button>
        </Col>
        <Col>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default PackageDetails;
