import { useEffect, useRef, useMemo, useState } from 'react';
import { useAppService } from '@ibe/components';
import { createUniqueId } from '@ibe/services';

const useRemountHooks = (): [string[], () => void] => {
  const appService = useAppService();
  const keyRef = useRef<string[]>([]);

  const [remountCounter, setReMountCounter] = useState(0);
  const [currency, setCurrency] = useState(appService.currency);

  const keys = useMemo(() => {
    if (remountCounter === 1) return keyRef.current as string[];
    const newKeys = [createUniqueId(), createUniqueId()];
    keyRef.current = newKeys;
    return newKeys;
  }, [remountCounter]);

  useEffect(() => {
    if (currency !== appService.currency) {
      setReMountCounter(numb => numb + 1);
      setCurrency(appService.currency);
    }
    if (!remountCounter) setReMountCounter(numb => numb + 1);
  }, [appService.currency]);

  const forceRemount = () => setReMountCounter(numb => numb + 1);

  return [keys, forceRemount];
};

export default useRemountHooks;
