import React, { useEffect, useState, JSX } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import { Element as ScrollTo } from 'react-scroll/modules';
import {
  Error,
  ImageProgressbar,
  MEDIAQUERY_DEFAULTS,
  Overlay,
  OverlayContainer,
  useMediaQuery
} from '@ibe/components';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { CheckoutStepComponentProps } from '@/Models/CheckoutStep';
import BackButton from './BackButton';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { useWorkflowService } from '@/Util/contexts';
import CheckoutPriceInfo from '@/components/checkout/CheckoutPriceInfo';
import {
  ApiComponent,
  ApiComponentType,
  ApiDirection,
  ApiFlightItem,
  ApiItemType,
  ApiSpecialRequest
} from '@ibe/api';
import OptionalInsuranceServices from '@/components/checkout/OptionalInsuranceServices';
import OptionalTransferServices from '@/components/checkout/OptionalTransferServices';
import { useMount } from 'react-use';
import { checkoutStartedEvent, MaskBookingService } from '@ibe/services';
import OptionalLuggageCard from '@/components/checkout/OptionalLuggageCard';

const Extras = observer(function Extras(props: CheckoutStepComponentProps) {
  const { store } = props;
  const { t } = useTranslationMGL('Checkout');

  const { workflow, setNextStepSlug, paymentResponse } = useWorkflowService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [inboundLuggageOptions, setInboundLuggageOptions] = useState<
    Array<ApiSpecialRequest> | undefined
  >(undefined);
  const [outboundLuggageOptions, setOutboundLuggageOptions] = useState<
    Array<ApiSpecialRequest> | undefined
  >(undefined);
  const scrollElementError = 'summaryErrorScroller';
  const isDesktopView = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });

  const components =
    store.packageCart?.packageModel.packageDetails &&
    store.packageCart?.packageModel.packageDetails[0].components;

  const hasOptionalInsurance =
    components &&
    components.some(
      component =>
        component.itemType === ApiItemType.INSURANCE &&
        component.componentType === ApiComponentType.OPTIONAL
    );

  const hasOptionalTransfer =
    components &&
    components.some(
      component =>
        component.itemType === ApiItemType.TRANSFER &&
        (component.componentType === ApiComponentType.OPTIONAL ||
          (component.componentType === ApiComponentType.REQUIRED &&
            component.selectableItems.length > 1))
    );

  const inboundFlightComponent: ApiComponent | undefined = components
    ?.filter(
      c =>
        c.itemType === ApiItemType.FLIGHT &&
        (c.selectedItems.at(0) as ApiFlightItem).direction === ApiDirection.INBOUND
    )
    ?.at(0);

  const outboundFlightComponent: ApiComponent | undefined = components
    ?.filter(
      c =>
        c.itemType === ApiItemType.FLIGHT &&
        (c.selectedItems.at(0) as ApiFlightItem).direction === ApiDirection.OUTBOUND
    )
    ?.at(0);

  const getLuggageOptions = (flightComponent: ApiComponent): ApiSpecialRequest[] | undefined => {
    return (flightComponent?.selectedItems.at(0) as ApiFlightItem).segment?.at(0)?.specialRequests;
  };

  useMount(async () => {
    if (store.booking) {
      if (outboundFlightComponent) {
        setOutboundLuggageOptions(getLuggageOptions(outboundFlightComponent));
      }
      if (inboundFlightComponent) {
        setInboundLuggageOptions(getLuggageOptions(inboundFlightComponent));
      }

      if (store.booking && store.booking.bookedItems.length > 0) {
        checkoutStartedEvent.broadcast({
          booking: MaskBookingService.maskBooking(store.booking),
          item: null
        });
      }
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const next = async (): Promise<void> => {
    setLoading(true);
    store
      .attemptBooking()
      .then(() => {
        setError(undefined);
        setNextStepSlug(workflow.nextStepSlug || '');
      })
      .catch(() => {
        setError(t(Keys.SummaryBookingError));
      })
      .finally(() => setLoading(false));
  };

  const back = () => {
    setNextStepSlug(store.handleNavigationClick(workflow, -1));
  };

  const Nav = (): JSX.Element => {
    return (
      <Row className="d-sm-flex">
        <Col className="col-sm-4 col-12">
          <BackButton
            onClick={() => {
              back();
            }}
          />
        </Col>
        <Col className="col-sm-8 col-12 d-flex justify-content-end">
          <Button className="navButton" color="primary" onClick={next}>
            {t(Keys.next)}
          </Button>
        </Col>
      </Row>
    );
  };

  const ErrorWrapper = observer(() => (
    <>
      <ScrollTo name={scrollElementError}>
        {error && !paymentResponse?.hasPaymentError && <Error message={error || ''} />}
      </ScrollTo>
    </>
  ));

  const ProgressbarWrapper = observer(
    (): JSX.Element => {
      const translatedSteps = store.steps.map(step => {
        return { ...step, description: t(step.description) };
      });
      return (
        <ImageProgressbar
          steps={translatedSteps}
          onClick={(index: number): void => {
            setNextStepSlug(store.handleNavigationClick(workflow, index - 1));
          }}
          showNotActiveIcon
          showDescriptionOnlyForActiveTab={!isDesktopView}
        />
      );
    }
  );

  return (
    <OverlayContainer className="checkout__container__wrapper m-0">
      <div className="checkout__container__content">
        <ProgressbarWrapper />
        <ErrorWrapper />
        <div className="checkout__container__content__title">{t(Keys.optionalExtras)}</div>
        <div className="checkout__container__content__optionalExtras row row-cols-1 row-cols-md-2">
          {outboundFlightComponent &&
            outboundLuggageOptions &&
            outboundLuggageOptions.length > 0 &&
            store.booking && (
              <OptionalLuggageCard
                booking={store.booking}
                luggage={outboundLuggageOptions}
                component={outboundFlightComponent}
                direction={ApiDirection.OUTBOUND}
                store={store}
              />
            )}
          {inboundFlightComponent &&
            inboundLuggageOptions &&
            inboundLuggageOptions.length > 0 &&
            store.booking && (
              <OptionalLuggageCard
                booking={store.booking}
                luggage={inboundLuggageOptions}
                component={inboundFlightComponent}
                direction={ApiDirection.INBOUND}
                store={store}
              />
            )}
          {hasOptionalTransfer && <OptionalTransferServices store={store} />}
          {hasOptionalInsurance && <OptionalInsuranceServices store={store} />}
        </div>
        {isDesktopView && <Nav />}
      </div>
      <CheckoutPriceInfo
        store={store}
        next={next}
        backButton={
          <BackButton
            className={'w-100'}
            onClick={() => {
              back();
            }}
          />
        }
      />
      {(store.isBusy || loading || store.isLoading) && <Overlay />}
    </OverlayContainer>
  );
});

export default Extras;
