import React, { JSX } from 'react';
import { PackageSearchStore, PackageStore, PackageParams, DateDisplay } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Keys from '../../../Translations/generated/en/search-content.json.keys';
import { ApiRoomContainer } from '@ibe/api';
import useTranslation from '../../../Util/useTranslation';
import KeysDateRange from '../../../Translations/generated/en/date-range-input.json.keys';

const NUMBER_DAY_PER_WEEK = 7;

export const getNumber = (occupancy: ApiRoomContainer[], property: string): number => {
  return (occupancy as (ApiRoomContainer & { [key: string]: number })[]).reduce(
    (total: number, current: ApiRoomContainer & { [key: string]: number }) =>
      total + current[property],
    0
  );
};

const RenderSearchContent = (
  _: PackageStore,
  searchStore: PackageSearchStore,
  searchParams?: Partial<PackageParams>
): JSX.Element => {
  const { t } = useTranslation('search-content');
  const { t: tDateRange } = useTranslation('date-range-input');
  const destinations = searchStore?.destinations;

  const durationToString = () => {
    const duration = searchParams?.duration;
    if (!duration) return '';
    const rest = duration % NUMBER_DAY_PER_WEEK;
    const numberOfWeeks = duration / NUMBER_DAY_PER_WEEK;
    if (rest || !numberOfWeeks)
      return tDateRange(KeysDateRange.nightComma, { count: duration, numberOfNights: duration });
    return tDateRange(KeysDateRange.weekComma, { count: numberOfWeeks, numberOfWeeks });
  };

  const destinationsToString = () => {
    if (destinations) {
      const destinationsNumber = destinations.length;
      if (destinationsNumber === 0) return '';
      if (destinationsNumber === 1) return `${destinations[0].name}, `;
      return `${destinations.length} ${t(Keys.destinationsLabel_plural)}, `;
    }
    return '';
  };

  return (
    <div className="package__search__content">
      <FontAwesomeIcon icon={faSearch} />
      <span>{destinationsToString()}</span>
      {!!searchParams?.occupancy && (
        <span>
          {`${getNumber(searchParams.occupancy, 'adults')} ${t(Keys.adult, {
            count: getNumber(searchParams.occupancy, 'adults')
          })}${
            getNumber(searchParams.occupancy, 'children') > 0
              ? `, ${getNumber(searchParams.occupancy, 'children')} ${t(Keys.child, {
                  count: getNumber(searchParams.occupancy, 'children')
                })}`
              : ''
          }, `}
        </span>
      )}
      <span>
        {searchParams?.startDate && searchParams?.endDate && (
          <>
            <DateDisplay date={searchParams.startDate} />
            {' - '}
            <DateDisplay date={searchParams.endDate} />
            {durationToString()}
          </>
        )}
      </span>
    </div>
  );
};

export default RenderSearchContent;
