import React, { ElementType, JSX } from 'react';
import { EditableArea, EditableComponent } from '@magnolia/react-editor';
import { MagnoliaComponentNode } from '@/Util/magnolia';
import { LinkTypeProps } from './Link';

interface MagnoliaComponentNodeLinkLIST extends Omit<MagnoliaComponentNode, '@nodes'> {
  '@nodes': string[];
}

type ListType = 'bullet' | 'ordered' | 'inline';
type LinkType = { [a: string]: LinkTypeProps } & MagnoliaComponentNodeLinkLIST;

const LinkList = (props: {
  links?: LinkType;
  subTitle: string;
  listStyle: ListType;
}): JSX.Element => {
  const { links, subTitle, listStyle = 'bullet' } = props;
  const Tag = tag[listStyle];
  return (
    <div className={'link-list'}>
      {subTitle ? <h4>{subTitle}</h4> : null}
      <Tag className={`${listStyle === 'inline' ? 'list-inline' : ''}`}>
        {!!links ? <EditableArea content={links} customView={LinkListElement} /> : <></>}
      </Tag>
    </div>
  );
};

function LinkListElement({
  content
}: {
  content: { [_: string]: LinkTypeProps } & MagnoliaComponentNodeLinkLIST;
}) {
  return (
    <>
      {content['@nodes'].map(nodeName => (
        <li key={content[nodeName]['@id']}>
          <EditableComponent content={content[nodeName]} />
        </li>
      ))}
    </>
  );
}

const tag: { [a in ListType]: ElementType } = {
  bullet: 'ul',
  ordered: 'ol',
  inline: 'ul'
};

export default LinkList;
