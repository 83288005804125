import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import Keys from '@/Translations/generated/en/ibe.json.keys';
import useTranslation from '@/Util/useTranslation';

const InternalFlightModalWarning = (props: {
  isOpen: boolean;
  isSearchButtonClicked: boolean;
  setIsOpenMaxOccupancyModal: (a: boolean) => void;
  onClose: () => void;
  onNext: () => void;
  onNextSearch: () => void;
}) => {
  const {
    isOpen,
    setIsOpenMaxOccupancyModal,
    onClose,
    onNext,
    isSearchButtonClicked,
    onNextSearch
  } = props;
  const { t } = useTranslation('ibe');

  const close = () => {
    setIsOpenMaxOccupancyModal(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpenMaxOccupancyModal(!isOpen)}
      container={document.getElementById('iso') || undefined}
      centered={true}
      className="internal-flight-modal"
    >
      <ModalBody>
        <div className="d-flex justify-content-between mb-4">
          <div>
            <div className="mb-2 fw-bold fs-5">{t(Keys.maxOccupancyModalTitle)}</div>
            <div>{t(Keys.maxOccupancyModalDescription)}</div>
          </div>
          <div>
            <span className="modal-close-box" onClick={close}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        </div>

        <Row>
          <Col lg={6}>
            <Button onClick={close} color="secondary" className="w-100 mb-1">
              {t(Keys.adjustNumberTraveler)}
            </Button>
          </Col>
          <Col lg={6}>
            <Button
              color="primary"
              onClick={isSearchButtonClicked ? onNextSearch : onNext}
              className="w-100 mb-1"
            >
              {t(Keys.searchYellowSunFlightOnly)}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InternalFlightModalWarning;
