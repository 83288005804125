import React, { useMemo, useState, JSX } from 'react';
import {
  PictureGallery,
  ImageGallerySlider,
  ScrollTabs,
  ShowMore,
  MapsWrapper,
  useMediaQuery,
  MEDIAQUERY_DEFAULTS,
  Overlay,
  OverlayContainer,
  StarRating,
  Theme
} from '@ibe/components';
import { useMount } from 'react-use';
import Keys from '@/Translations/generated/en/hotel-info.json.keys';
import useHotel from '@/Hooks/useHotel';
import { useSearchParams } from 'next/navigation';
import { ApiBestPricePackageModel, ApiGeoUnitFromJSON, ApiImage, ApiImageFromJSON } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@emotion/react';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import { parseInt } from 'lodash-es';

export type LocationMetaInfo = Partial<{
  description: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}>;

const HotelInfo = () => {
  const cmsHotelService = useHotel();
  const queryParams = useSearchParams();
  const matches = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [loading, setLoading] = useState<boolean>();
  const [hotel, setHotel] = useState<ApiBestPricePackageModel | null>(null);
  const [galleryChangeTrigger, setGalleryChangeTrigger] = useState<boolean>(false);
  const [modalOpenImageIdx, setModalOpenImageIdx] = useState<number | undefined>(undefined);
  const [location, setLocation] = useState<LocationMetaInfo | undefined>(undefined);
  const theme = useTheme() as Theme;
  const placeHolderImage = theme?.placeholderImages?.package;

  const { t } = useTranslationMGL('HotelInfo');
  const getLocationMetaInfo = (item: ApiBestPricePackageModel): LocationMetaInfo | undefined => {
    return item.metaInfo ? item.metaInfo.contentByMetaType?.LOCATION?.content : undefined;
  };

  useMount(() => {
    if (queryParams.has('code')) {
      setLoading(true);
      cmsHotelService
        .fetchHotel(queryParams.get('code') || '')
        .then(hotel => {
          setHotel(hotel);
          setLocation(getLocationMetaInfo(hotel));
        })
        .finally(() => setLoading(false));
    }
  });

  const getTags = (): JSX.Element => {
    const tagsContent = hotel?.metaInfo
      ? hotel.metaInfo.contentByMetaType?.TAGS?.content
      : undefined;
    return (
      <>
        {tagsContent && tagsContent.tags ? (
          <div className={'best-price-package-details__tags'}>
            {(tagsContent.tags as Array<string>).map(tag => (
              <span key={`tag-${tag}`}>{tag}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };
  const ShowMoreButton = (toggleTruncate: () => void, label: string) => {
    return (
      <ShowTruncateButton
        toggleTruncate={toggleTruncate}
        label={label}
        className="showMoreButton"
      />
    );
  };

  const ShowLessButton = (toggleTruncate: () => void, label: string) => {
    return (
      <ShowTruncateButton
        toggleTruncate={toggleTruncate}
        label={label}
        className="showLessButton"
      />
    );
  };

  const galleryImages: ApiImage[] = useMemo(() => {
    const images = hotel?.media?.images.map(img => img.large || img.small) || [];
    if (hotel?.media?.mainImage) {
      images.unshift(hotel?.media?.mainImage.large || hotel?.media?.mainImage.small);
    }
    return images.length > 0 ? images : [ApiImageFromJSON({ url: placeHolderImage })];
  }, [hotel, placeHolderImage]);

  const toggleModal = (): void => {
    setModalOpenImageIdx(typeof modalOpenImageIdx !== 'undefined' ? undefined : 0);
  };

  const handleImageClick = (imageIdx: number): void => {
    setModalOpenImageIdx(imageIdx);
    toggleTrigger();
  };

  const toggleTrigger = (): void => {
    setGalleryChangeTrigger(value => !value);
  };

  return (
    <OverlayContainer>
      <div className="best-price-package-details">
        <div className="best-price-package-details__gallery-container">
          <PictureGallery
            images={galleryImages}
            hideButtons
            twoSmallImagesMode
            onImageClick={handleImageClick}
          />
          {hotel?.hotelLogo?.extraSmall?.url && (
            <div className={'best-price-package-details__gallery-container__hotel-logo-container'}>
              <img
                className={'best-price-package-details__gallery-container__hotel-logo'}
                src={hotel?.hotelLogo.extraSmall.url}
                alt=""
              />
            </div>
          )}
        </div>
        {getTags()}
        <div>
          <ScrollTabs
            anchors={[
              { label: t(Keys.overview), id: 'details-overview' },
              ...(!!location?.description || (!!location?.latitude && !!location?.longitude)
                ? [{ label: t(Keys.location), id: 'details-location' }]
                : []),
              ...(!!location?.description
                ? [{ label: t(Keys.accommodation), id: 'details-accommodation' }]
                : [])
            ]}
            scrollOffset={60}
          />
        </div>
        <div className="d-flex justify-content-between">
          <h2 id="details-overview">
            <span>{hotel?.name || ''}</span>
            {!!hotel?.category?.code && (
              <span className="ml-3">
                <StarRating numberOfStars={parseInt(hotel.category.code, 10)} />
              </span>
            )}
          </h2>
        </div>
        <div>
          <p className="best-price-package-details__geo-location">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span className="ml-2">{hotel?.geoAssignment?.location || ''}</span>
          </p>
        </div>
        {hotel?.shortDescription && (
          <ShowMore
            lines={7}
            buttonClass="btn-secondary"
            more={t(Keys.show_more)}
            less={t(Keys.show_less)}
            className="best-price-package-details__short mt-2"
            showMoreRender={ShowMoreButton}
            showLessRender={ShowLessButton}
            showAllInitially
          >
            {hotel.shortDescription}
          </ShowMore>
        )}
        {(!!location?.description || (!!location?.latitude && !!location?.longitude)) && (
          <>
            <h2 id="details-location">{t(Keys.location)}</h2>
            <Row>
              <Col
                xs={12}
                md={!!location?.latitude && !!location?.longitude ? 8 : 12}
                className="best-price-package-details__location__container"
              >
                {!!location?.description && (
                  <ShowMore
                    lines={7}
                    more={t(Keys.show_more)}
                    less={t(Keys.show_less)}
                    className="best-price-package-details__location"
                    showMoreRender={ShowMoreButton}
                    showLessRender={ShowLessButton}
                    showAllInitially
                  >
                    {location.description}
                  </ShowMore>
                )}
              </Col>
              {!!location?.latitude && !!location?.longitude && (
                <Col xs={12} md={4} className="best-price-package-details__map hide-in-print">
                  <MapsWrapper
                    markers={[
                      {
                        iconUrl: '/leaflet-marker.svg',
                        geoData: {
                          location: hotel?.name || '',
                          latitude: location.latitude,
                          longitude: location.longitude,
                          geoUnit: hotel?.geoAssignment?.geoUnit || ApiGeoUnitFromJSON(null)
                        },
                        name: hotel?.name || ''
                      }
                    ]}
                    centerMap={{
                      lat: location.latitude,
                      lng: location.longitude
                    }}
                    zoomMap={11}
                    containerHeight={matches ? undefined : '300px'}
                    googleMapsOptions={{
                      panControls: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false
                    }}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
        {hotel?.description && (
          <>
            <h2 id="details-accommodation" className="mt-3">
              {t(Keys.accommodation)}
            </h2>
            <ShowMore
              lines={7}
              more={t(Keys.show_more)}
              less={t(Keys.show_less)}
              className="best-price-package-details__long"
              showMoreRender={ShowMoreButton}
              showLessRender={ShowLessButton}
              showAllInitially
            >
              {hotel.description}
            </ShowMore>
          </>
        )}
        <Modal
          className="best-price-package-details__modal"
          isOpen={typeof modalOpenImageIdx !== 'undefined'}
          toggle={toggleModal}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody>
            <FontAwesomeIcon
              icon={faTimes}
              className="best-price-package-details__modal__close"
              onClick={(): void => setModalOpenImageIdx(undefined)}
            />
            <Row className="p-2 best-price-package-details__modal__name-row align-content-end">
              <Col className="">
                <Row className="mb-2">
                  <Col>
                    {!!hotel?.category?.code && (
                      <span className="mr-3">
                        <StarRating numberOfStars={parseInt(hotel.category.code, 10)} />
                      </span>
                    )}
                    <span className="best-price-package-details__geo-location">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      <span className="ml-2">{hotel?.geoAssignment?.location || ''}</span>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="h4">{hotel?.name || ''}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ImageGallerySlider
              images={galleryImages}
              initialGalleryImageIndex={modalOpenImageIdx}
              galleryChangeTrigger={galleryChangeTrigger}
            />
          </ModalBody>
        </Modal>
      </div>
      {loading && <Overlay />}
    </OverlayContainer>
  );
};

const ShowTruncateButton = ({
  toggleTruncate,
  label,
  className
}: {
  toggleTruncate: () => void;
  label: string;
  className: string;
}): JSX.Element => {
  return (
    <Container className={className}>
      <Row>
        <Col>
          <hr />
        </Col>
        <Col>
          <Button onClick={toggleTruncate}>{label}</Button>
        </Col>
        <Col>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default HotelInfo;
