import React, { useMemo, JSX } from 'react';
import StaticQuotes, { Feedback } from '@/components/quotes/StaticQuotes';

const keys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19] as const;
type DigitType = typeof keys[number];
type Feedbacks = `feedbacks${DigitType}`;
type Props = {
  feedbacks: { [key in Feedbacks]: Feedback };
} & { [key: string]: string };

const Quotes = (props: Props): JSX.Element => {
  const { feedbacks } = props;

  const transformedFeedbacks = useMemo(() => {
    return [...Array(20).keys()]
      .filter(key => !!(feedbacks[`feedbacks${key}` as keyof Props['feedbacks']] as Feedback))
      .map(key => feedbacks[`feedbacks${key}` as keyof Props['feedbacks']] as Feedback);
  }, [feedbacks]);

  return (
    <div>
      {transformedFeedbacks.length > 0 ? <StaticQuotes feedbacks={transformedFeedbacks} /> : <></>}
    </div>
  );
};

export default Quotes;
