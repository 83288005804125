import { useLocalObservable } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CheckoutStore from '../../components/checkout/CheckoutStore';
import BlankPageLayout from '../../Layouts/BlankPageLayout';
import { useWorkflowService, WorkflowContext } from '@/Util/contexts';
import workflowCheckout from '@/Util/steps';
import { useApi, useIsOnProductPage, useWindow } from '@ibe/components';
import { useClientSideBootingFinished } from '@/Util/useClientSideBootingFinished';
import { CheckoutStep } from '@/Models/CheckoutStep';
import { useMount } from 'react-use';
import { stepSessionStorage } from '@/Util/globals';
import { UsePaymentResponse } from '@/Util/getPaymentStatus';
import { useRouter } from 'next/router';
import StepId from '@/components/checkout/StepId';
import { productSearchSessionKey } from '@/Config/config';

const Checkout = ({ ibeUrl, homeUrl }: { ibeUrl: string; homeUrl: string }) => {
  const api = useApi();
  const router = useRouter();
  const { workflow } = useWorkflowService();
  const clientSideBootingFinished = useClientSideBootingFinished();
  const [nextStepSlug, setNextStepSlug] = useState<string | undefined>(undefined);
  const [foundStep, setFoundStep] = useState<CheckoutStep | undefined>();
  const [paymentResponse, setPaymentResponse] = useState<UsePaymentResponse | undefined>(undefined);
  const window = useWindow();
  const queryParams = window?.sessionStorage.getItem(productSearchSessionKey);
  const returnUrl = `${ibeUrl}?${queryParams}`;
  const { setIsOnProductPage } = useIsOnProductPage();

  const store = useLocalObservable(() => new CheckoutStore(api, returnUrl));

  useMount(async () => {
    await store.loadCart();

    const persistedStep = stepSessionStorage.get();
    if (!!persistedStep) {
      setNextStepSlug(persistedStep);
    } else {
      setNextStepSlug(workflowCheckout[0].slug || '');
    }
    setIsOnProductPage(true);
  });

  useEffect(() => {
    const nextFoundStep = workflowCheckout.find(step => step.slug === nextStepSlug);
    if (!!nextFoundStep && !!nextStepSlug) {
      setFoundStep(nextFoundStep);
      stepSessionStorage.set(nextStepSlug);
    } else if (
      (!!nextFoundStep && nextStepSlug?.includes(homeUrl)) ||
      nextStepSlug?.includes(ibeUrl)
    ) {
      setFoundStep(nextFoundStep);
      router.push(nextStepSlug);
    }
  }, [nextStepSlug]);

  useEffect(() => {
    if (clientSideBootingFinished) {
      workflow.setSteps(workflowCheckout);
    }
  }, [clientSideBootingFinished]);

  useEffect(() => {
    if (!!foundStep) {
      workflow.setCurrentStep(foundStep);
      store.setActiveStep(foundStep.id || StepId.EXTRAS);
    }
  }, [foundStep]);

  return (
    <WorkflowContext.Provider
      value={{ workflow, setNextStepSlug, setPaymentResponse, paymentResponse }}
    >
      <BlankPageLayout>
        <div className="max-width checkout__container">
          {!!foundStep?.component && (
            <foundStep.component store={store} ibeUrl={ibeUrl} homeUrl={homeUrl} />
          )}
        </div>
      </BlankPageLayout>
    </WorkflowContext.Provider>
  );
};

export default Checkout;
